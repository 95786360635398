import styled from 'styled-components';

const Title = styled.h1``;

const Subtitle = styled.p`
    margin-top: 0px;
    margin-bottom: 16px;
    font-size: 16px;
`;

const Button = styled.button`
    background-color: #00000080;
    color: gray;
    border: none;
    border-radius: 8px;
    padding: 10px 20px 10px 20px;
    font-weight: 700;
    font-size: 18px;

    &:enabled {
        color: white;
    }
    &:hover:enabled {
        background-color: white;
        color: black;
    }

    transition: background-color 400ms, color 400ms ease;
`;

export { Title, Subtitle, Button };
