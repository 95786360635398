import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import BlastWaveLogo from './BlastWaveLogo';
import { Button } from './style';
import './Swipe.css';
import PinInput from 'react-pin-input';

const Root = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Header = styled.h1`
    box-sizing: border-box;
    margin: 0px;
    width: 100%;
    padding-left: 32px;
    padding-top: 32px;
    text-align: left;
    font-weight: 400;
`;

const Footer = styled.h1`
    box-sizing: border-box;
    margin: 0px;
    width: 100%;
    padding-left: 32px;
    padding-bottom: 32px;
    text-align: left;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const FormRoot = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const RoundedInput = styled.input`
    box-sizing: border-box;
    background-color: #00000080;
    font-size: 2rem;
    border-radius: 1em;
    border: 3px solid white;
    text-align: center;
    color: white;
    outline: none;
`;

const arrayBufferToHex = (buffer) => [...new Uint8Array(buffer)].map((b) => b.toString(16).padStart(2, '0')).join('');

const fromHexString = (hexString) => Uint8Array.from(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));

const generateProvisionMessage = async (invitation, pin, peerKey) => {
    const enc = new TextEncoder();
    const ecdh = { name: 'ECDH', namedCurve: 'P-256' };
    const peerPublic = await window.crypto.subtle.importKey('raw', fromHexString(peerKey), ecdh, false, []);
    const key = await window.crypto.subtle.generateKey(ecdh, false, ['deriveKey']);
    const publicKey = arrayBufferToHex(await window.crypto.subtle.exportKey('raw', key.publicKey));
    const pinHash = await window.crypto.subtle.digest('SHA-256', enc.encode(pin));
    const aesKey = await window.crypto.subtle.deriveKey(
        { name: 'ECDH', public: peerPublic },
        key.privateKey,
        { name: 'AES-GCM', length: 256 },
        true,
        ['encrypt']
    );
    return {
        type: 'provision',
        payload: {
            public_key: publicKey,
            invitation: arrayBufferToHex(
                await window.crypto.subtle.encrypt(
                    { name: 'AES-GCM', iv: pinHash.slice(0, 12) },
                    aesKey,
                    enc.encode(invitation)
                )
            ),
        },
    };
};

const GatewayForm = ({ networkName, onRegister }) => {
    const pinStyle = {
        backgroundColor: '#00000080',
        fontSize: '2rem',
        border: '3px solid white',
        borderRadius: '0.5em',
        color: 'white',
    };
    const [ipAddress, setIpAddress] = useState('');
    const [pin, setPin] = useState('');

    return (
        <FormRoot>
            <h1>{networkName?.toUpperCase()} GATEWAY REGISTRATION</h1>
            <h2>GATEWAY IP ADDRESS:</h2>
            <RoundedInput value={ipAddress} onChange={(event) => setIpAddress(event.target.value)} />
            <h2>PROVISIONING PIN CODE:</h2>
            <PinInput
                initialValue={pin}
                length={6}
                type='numeric'
                inputStyle={pinStyle}
                onChange={(value) => setPin(value)}
            />
            <Button
                style={{ marginTop: '2rem' }}
                disabled={ipAddress.length < 4 || pin.length !== 6}
                onClick={() => onRegister(ipAddress, pin)}
            >
                REGISTER
            </Button>
        </FormRoot>
    );
};

const GatewayRegistration = ({ invitation }) => {
    const popupRef = useRef(null);
    const [gateway, setGateway] = useState(null);
    const [message, setMessage] = useState({ message: '', showCancel: false });
    const [networkName, setNetworkName] = useState(null);

    useEffect(() => {
        const fetchNetworkName = async (networkID) => {
            try {
                setMessage({ message: 'Fetching network information...', showCancel: false });
                const req = await fetch(`https://lighthouse.blastwave.io/network/${networkID}`);
                const networkMeta = await req.json();
                setNetworkName(networkMeta.name);
                setMessage({ message: null, showCancel: false });
            } catch {
                setMessage({ message: 'Failed to fetch network information', showCancel: false });
                return;
            }
        };
        try {
            const decodedInvitation = JSON.parse(window.atob(invitation));
            console.log(decodedInvitation);
            fetchNetworkName(decodedInvitation.network_id);
        } catch {
            setMessage({ message: 'Invalid invitation', showCancel: false });
            return;
        }
    }, [invitation]);

    const messageCallback = useCallback(
        async ({ origin, data }) => {
            if (!gateway) return;

            const gwOrigin = `http://${gateway.ipAddress}`;
            if (origin !== gwOrigin) return;

            switch (data?.type) {
                case 'peer_key': {
                    const reply = await generateProvisionMessage(invitation, gateway.pin, data.payload);
                    popupRef.current?.postMessage(reply, gwOrigin);
                    break;
                }
                case 'cancelled':
                    setMessage({ message: null, showCancel: false });
                    break;
                case 'failed':
                    setMessage({ message: null, showCancel: false });
                    break;
                case 'success':
                    setMessage({
                        message: 'Registration successful. You may now close this browser window.',
                        showCancel: false,
                    });
                    break;
                default:
                    break;
            }
        },
        [gateway, invitation]
    );

    useEffect(() => {
        if (!gateway) return;
        console.log(gateway);

        if (popupRef.current && !popupRef.current.closed) {
            popupRef.current.location.href = `http://${gateway.ipAddress}`;
        } else {
            popupRef.current = window.open(`http://${gateway.ipAddress}`, '_blank', 'popup,width=800,height=600');
        }
        setMessage({
            message: 'Waiting for registration to complete..',
            showCancel: true,
        });
    }, [gateway]);

    useEffect(() => {
        window.addEventListener('message', messageCallback);

        return () => window.removeEventListener('message', messageCallback);
    }, [messageCallback]);

    useEffect(() => () => popupRef.current?.close(), []);

    if (message.message) {
        return (
            <>
                <h1>{message.message}</h1>
                {message.showCancel && (
                    <Button onClick={() => setMessage({ message: null, showCancel: false })}>CANCEL</Button>
                )}
            </>
        );
    } else {
        return <GatewayForm networkName={networkName} onRegister={(ipAddress, pin) => setGateway({ ipAddress, pin })} />;
    }
};

const useHash = () => {
    const [hash, setHash] = React.useState(window.location.hash);
    const listenToPopstate = () => setHash(window.location.hash);
    React.useEffect(() => {
        window.addEventListener('popstate', listenToPopstate);
        return () => {
            window.removeEventListener('popstate', listenToPopstate);
        };
    }, []);
    return hash;
};

const App = () => {
    const hash = useHash();
    const [invitation, setInvitation] = useState(null);

    useEffect(() => {
        if (!hash) return;
        setInvitation(hash.substring(1));
    }, [hash]);

    return (
        <Root>
            <Header>
                <b>Blast</b>Shield™
            </Header>
            <Spacer />
            {invitation && <GatewayRegistration invitation={invitation} />}
            <Spacer />
            <Footer>
                <a href='https://www.blastwave.com' target='_blank' rel='noreferrer'>
                    <BlastWaveLogo />
                </a>
            </Footer>
        </Root>
    );
};

export default App;
